import React from 'react';
import { graphql } from 'gatsby';
import { BlogPostHeader } from 'components/BlogPostHeader';
import Layout from 'components/Layout';
import { seoContext } from 'context/seo-context';

import './index.scss';

type Node = {
  node: {
    fields: {
      slug: string;
    };
    frontmatter: {
      date: string;
      title: string;
      featuredImage: {
        childImageSharp: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fluid: any;
        };
      };
      wordCount: number;
    };
    id: string;
  };
};

type AllMarkdownRemark = {
  edges: Node[];
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                fluid(
                  quality: 100
                  maxWidth: 2000
                  srcSetBreakpoints: [200, 340, 520, 890, 992, 1200, 1340, 1520, 1890, 2000]
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            wordCount
          }
        }
      }
    }
  }
`;

interface BlogIndexProps {
  data: {
    allMarkdownRemark: AllMarkdownRemark;
  };
}

const BlogIndex: React.FC<BlogIndexProps> = (props: BlogIndexProps) => {
  return (
    <seoContext.Provider value={{ title: 'Home' }}>
      <Layout className="index">
        <section className="section section-content">
          <div className="container">
            <div className="center">
              <article>
                {props.data.allMarkdownRemark.edges.map((node: Node) => {
                  const { title, wordCount } = node.node.frontmatter;
                  return (
                    <BlogPostHeader
                      fluidFeatureImage={node.node.frontmatter.featuredImage.childImageSharp}
                      key={node.node.fields.slug}
                      slug={node.node.fields.slug}
                      title={title}
                      wordCount={wordCount}
                    />
                  );
                })}
              </article>
            </div>
          </div>
        </section>
      </Layout>
    </seoContext.Provider>
  );
};

export default BlogIndex;
